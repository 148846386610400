import React, {FC, useState} from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form';

const SignupForm = styled.div`
  @media (max-width: 420px) {
    margin-top: 200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;  
  }
`
const EmailInput = styled.input`
  background: transparent;
  font-size: 18px;
  border-bottom: 1px solid hsla(0,0%,100%,.4);
  border-radius: 0;
  padding: 16px 20px;
  margin-right: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  min-width: 380px;
  color: white;

  @media (max-width: 420px) {
    min-width: 0;
    background: grey;
    border-radius: 10px;
    border: none;
    margin-right: 0;
  }

  ::placeholder {
    color: hsla(0,0%,100%,.6);
  }

  :focus {
    outline: 0;
  }
`

const SignupButton = styled.input`
  background-color: gold;
  border: none;
  border-radius: 10px;
  padding: 20px 25px;
  font-size: 24px;

  @media (max-width: 420px) {
    font-size: 20px;
    padding: 15px 20px;
    margin-top: 10px;
  }
`

const SignupText = styled.div`
  margin-top: 15px;
  color: hsla(0,0%,100%,.8);
  font-size: 14px;

  @media (max-width: 420px) {
    text-align: center;
  }

`

const SignupSuccessText = styled(SignupText)`
  color: greenyellow;
  font-weight: 500;
`

const Signup: FC = () => {
  const [signup, setSignup] = useState(false);
  const { handleSubmit, register } = useForm();
  const onSubmit = async (data: any, e: any ) => {    
   await fetch('https://submit-form.com/QnrrTutQ',
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
    .catch((error) => {
      console.error('Error:', error);
    });

    e.target.reset();
    setSignup(true);
  };
  const signupText = signup ? <SignupSuccessText>You have successfully signed up</SignupSuccessText> :
  <SignupText>We'll keep you up to date on our progress, you can opt-out at any time.</SignupText>;


  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        // action={"https://submit-form.com/QnrrTutQ"}
        // method="POST"
      >
        <input
          type="checkbox"
          name="_QnrrTutQ"
          style={{display: 'none'}}
          tabIndex={-1}
          autoComplete="off"
        />
        <div>
          <SignupForm>
            <EmailInput
              type="email"
              name="EMAIL"
              placeholder="Email address"
              ref={register({
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid email address"
                }
              })}
            />
            <SignupButton
              type="submit"
              value="Get Early Access"
              name="subscribe"
            />
            {signupText}
          </SignupForm>
        </div>
      </form>
    </div>
  )
}


export default Signup
