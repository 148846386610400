import React, { FC, useLayoutEffect, useState, useEffect } from 'react'
import styled from 'styled-components'
import CountUp from 'react-countup'
import useWindowScroll from 'react-use/lib/useWindowScroll'
import useMeasure, { UseMeasureRef } from 'react-use/lib/useMeasure'
import PhoneFrame from '../../images/phone-frame.png'
import AppBatteryIcon from '../../images/app-battery.svg'
import AppArrowIcon from '../../images/expand_less-black.svg'
// import LogoSvg from '../../images/wonder-logo.svg'
import { Section } from '../layout/Section'
import { MainAndAside } from '../layout/Grid'
import { useLogoVisibility } from '../layout/Page'
import { FullBleed } from '../layout/FullBleed'
import { BigStats } from './BigStats'
import { AppCTA } from '../CTA'
import SignUp from '../Signup'

const PhoneImg = styled.img`
    width: 100%;
`

const MockupContainer = styled.div`
    margin: 0 auto;
    width: 40%;
    max-width: 400px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transform: rotateZ(9deg);
    line-height: 1;
    box-sizing: border-box;
`

const AppScreen = styled.div`
    font-family: "Exo", sans-serif;
    font-size: 1em;
    position: absolute;
    width: 92%;
    height: 97%;
    top: 2%;
    left: 1%;
    z-index: -1;
    transform: perspective(300px) rotateY(-3.9deg) translateZ(-5px);
    border-radius: 0.75em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    overflow: hidden;

    @media (max-width: 420px) {
      transform: perspective(300px) rotateY(-6.9deg) translateZ(-5px);
  }
`

const AppContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem;
`
const AppToolbar = styled.div`
  /* font-weight: 500; */
  font-size: 12px;
  padding: 4.5% 8% 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 420px) {
    font-size: 8px;
  }
`

const AppBalance = styled.div`
  font-family: monospace;
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 0.5rem;
  font-weight: 500;
  width: 100%;
  text-align: center;

  @media (max-width: 420px) {
    font-size: 18px;
  }

`

const AppAPY = styled.div`
  font-size: 14px;
  margin-bottom: 2rem;
  @media (max-width: 420px) {
    font-size: 10px;
  }
`

const AppFooter = styled.div`
  width: 100%;
  margin-bottom: 2%;
`

const AppArrow = styled.img`
  width: 25%;
  margin: 0 auto;
`

const AppBattery = styled.img`
  width: 15%;
`

const Mockup: FC = () => {
  return <PhoneImg src={PhoneFrame} alt="wonder" />
}

const MissionText = styled.h1`
  margin-top: 50px;
  margin-bottom: 64px;
  font-size: 36px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: -.05em;
  font-family: Poppins,Roboto,Open Sans,sans-serif;
  text-align: left;
  span {
    color: gold;
  }
  @media (min-width: 420px) {
    font-size: 55px;
    margin-top: 150px;
    line-height: 75px;
  }
`

const BottomSection = styled(Section)`
  margin-bottom: 0;
  > * {
    max-width: 75ch;
  }
`

const StyledFullBleed = styled(FullBleed)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 200px;
`

export const Intro: FC = () => {
  const { y } = useWindowScroll()
  const [ref, { height, top }] = useMeasure()
  const [, setLogoVisibility] = useLogoVisibility()
  const [time, setTime] = useState('00:00');

  useEffect(() => {
    const getTime = setInterval(() => {
      const time = new Date().toLocaleTimeString();
      setTime(time.substring(0, (time.length - 3)));
    }, 1000);
    return () => clearInterval(getTime);
  }, []);

  useLayoutEffect(() => {
    setLogoVisibility(y > height + top)
  }, [y, height, top])

  return (
    <StyledFullBleed dark ref={ref as UseMeasureRef<HTMLDivElement>}>
      <Section>
        {/* <Logo /> */}
        <MainAndAside>
          <MissionText>
            Programmable digital dollars with built-in yield
          </MissionText>
          <MockupContainer>
          <PhoneImg src={PhoneFrame} alt="wonder" />
          <AppScreen>
            <AppToolbar>
              {time}
              <AppBattery src={AppBatteryIcon} />
            </AppToolbar>
            <AppContent>
              <AppBalance>
                $563.
              <CountUp 
                duration={6000}
                start={1000}
                end={6000}
                useEasing={false}
                />
              </AppBalance>
              <AppAPY>
              earning 5.7% APY
              </AppAPY>
            </AppContent>
            <AppFooter>
              <AppArrow src={AppArrowIcon} />
            </AppFooter>
          </AppScreen>
            {/* <AppCTA href="https://app.wonder.org">Use the app</AppCTA> */}
          </MockupContainer>
        </MainAndAside>
        <SignUp />
      </Section>
      {/* <BottomSection>
      </BottomSection> */}
    </StyledFullBleed>
  )
}
